@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import '~rsuite/dist/rsuite.min.css'; // Rsuite styles
@import 'react-phone-input-2/lib/style.css'; // phone number input styles

h1,
h2,
h3,
h4,
h5,
h6,
li {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

p,
strong {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

.rs-modal-lg {
  width: 100%;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  max-width: none;
  max-height: none;
  margin: 0px;
  border-radius: 0px;

  .rs-modal-content {
    border-radius: 0px;
    padding: 0 !important;
    overflow: hidden; // needed for scla form uploader - hotfix
  }
}
